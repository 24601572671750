/** @jsx jsx */
import HomePageLayout from '../../../components/HomePageLayout';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import HeroSectionContainer from '@fify.at/gatsby-theme-base/src/components/HeroSection/HeroSectionContainer';
import Heading from '@fify.at/gatsby-theme-base/src/components/Heading/Heading';
import Subheading from '@fify.at/gatsby-theme-base/src/components/Subheading/Subheading';
import Text_calltoaction from '../../../components/raw_templates/Text_calltoaction';
import Section from '@fify.at/gatsby-theme-base/src/components/Section/Section';
import Link from '@fify.at/gatsby-theme-base/src/components/Link';

export default function Hausankauf({ location, data }) {
  return (
    <HomePageLayout
      location={location}
      lang="de"
      title="In nur 48 Stunden Ihr Haus verkaufen"
      description="WirkaufendeinHaus.at versteht aufgrund seiner jahrelangen Erfahrung im Immobilienbereich, die damit einhergehenden Herausforderungen und unterstützt Sie als starker Partner vollkommen transparent und mit Handschlags-Qualität bei jedem Schritt im Hausankaufsprozess."
      image={data.hero.childImageSharp.fluid}
      keywords={['Hausankauf']}
      header={{ attachment: 'default', appearance: 'default', fluid: true }}
    >
      <HeroSectionContainer
        fluid={false}
        orientation="southEast"
        variant="alpha"
        title="Ihr Haus – Ihre Regeln"
        subtitle="Schnell, einfach, fair und sicher."
        image={data.hero}
      />

      <Section container="small">
        <Heading>
          So einfach und schnell funktioniert unser Ankaufsprozess
        </Heading>
        <p>
          Für viele ist der Verkauf eines Hauses nicht nur ein völlig neue
          Erfahrung, sondern meist auch mit Emotionen und Unsicherheiten
          verbunden. WirkaufendeinHaus.at versteht aufgrund seiner jahrelangen
          Erfahrung im Immobilienbereich die damit einhergehenden
          Herausforderungen und unterstützt Sie als starker Partner vollkommen
          transparent und mit Handschlagqualität bei jedem Schritt im
          Hausankaufsprozess – auch wenn es einmal schnell gehen muss.
        </p>
        <Subheading>Auf Knopfdruck Ihr Haus privat verkaufen</Subheading>
        <p>
          Unser österreichweiter Hausankauf gestaltet die Kaufabwicklung Ihrer
          Immobilien so schnell und unkompliziert wie möglich. Wenn der
          Zeitpunkt für Sie gekommen ist, an dem Sie sich dazu entschließen, Ihr
          Haus privat zu verkaufen, können Sie Ihre Immobiliendaten schnell und
          bequem über unseren{' '}
          <Link to="/online-immobilienbewertung/">
            Online Immobilienbewertungsrechner
          </Link>{' '}
          eingeben. Ihre <Link to="/datenschutzerklaerung/">Daten</Link> werden
          selbstverständlich von uns vertraulich behandelt.
          <br />
          Auf Knopfdruck erhalten Sie umgehend eine erste Immobilienbewertung
          und können dann in Ruhe entscheiden, ob Sie einen{' '}
          <Link to="/terminbuchung/">
            Termin mit einem unserer regionalen Immobilienexperten buchen
          </Link>{' '}
          möchten.
        </p>
        <p>
          Oder Sie nehmen gleich direkten Kontakt mit einem Profi von
          WirkaufendeinHaus.at auf und vereinbaren einen{' '}
          <Link to="/terminbuchung/">Besichtigungstermin</Link>. Unser Experte
          nimmt vor Ort eine aussagekräftige Wertindikation vor und erstellt auf
          Basis dieses kostenlosen Bewertungsgutachtens und nach Sichtung aller
          erforderlichen Unterlagen innerhalb von 48 Stunden ein verbindliches
          Angebot für Sie.
          <br />
          Nehmen Sie es an, dann ist Ihr Haus sicher an uns verkauft.
          <br />
          Unser Team kümmert sich anschließend vertraulich und diskret um
          sämtliche Formalitäten, Verträge und Behördengänge. Der Kaufpreis wird
          anschließend über den Notar/Rechtsanwalt als Treuhänder sicher an Sie
          überwiesen.
        </p>
      </Section>
      <Text_calltoaction
        sectionBackground="muted"
        title="Nutzen Sie jetzt unseren kostenlosen Service!"
        text="<p>Holen Sie sich auf Knopfdruck eine erste Immobilienbewertung und/oder vereinbaren Sie einen
kostenlosen Termin mit uns.</p>"
        buttons={[
          {
            text:
              'Zur Online Immobilienbewertung <i class="fal fa-arrow-right"></i>',
            to: '/online-immobilienbewertung/',
          },
          {
            text: 'Jetzt Termin vereinbaren <i class="fal fa-arrow-right"></i>',
            to: '/terminbuchung/',
          },
        ]}
      />
    </HomePageLayout>
  );
}

export const pageQuery = graphql`
  {
    hero: file(name: { eq: "hero-sofunktionierts" }) {
      childImageSharp {
        fluid(maxWidth: 2340, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
